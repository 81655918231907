<template>
  <div class="page page-login">
    <Header/>
    <DialogCard :title="$t('Login')">
      <ValidationObserver ref="form" tag="form" class="form app-form" @submit.prevent="onSubmit()">
        <b-form-group>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label class="form-control-label">{{ $t("Username") }}<i class="required"></i></label>
            <b-form-input v-model="formData.username" :placeholder="$t('Enter an username')" :readonly="loading"
                          autocomplete="off"/>
            <div class="msg-validate-error">{{ errors[0] }}</div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label class="form-control-label">{{ $t("Password") }}<i class="required"></i></label>
            <div class="position-relative">
              <b-form-input style="padding-right: 45px !important" :type="showPass ? 'text' : 'password'"
                            v-model="formData.password" :placeholder="$t('Enter a password')" :readonly="loading"
                            autocomplete="off"/>
              <a href="#" class="position-absolute" style="top: 15px; right: 15px"
                 @click.prevent="showPass = !showPass"><i
                  :class="['fa-solid', showPass ? 'fa-eye-slash' : 'fa-eye']"></i></a>
            </div>
            <div class="msg-validate-error">{{ errors[0] }}</div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group>
          <b-button type="submit" variant="primary" block size="lg" class="font-weight-bold" :disabled="loading">
            {{ $t("Login") }}
          </b-button>
        </b-form-group>
        <div class="d-flex justify-content-between">
          <a href="#" @click.prevent="$router.push({name: 'Register'})">{{ $t("Register") }}</a>
          <a href="#" @click.prevent="$router.push({name: 'RecoveryPassword'})">{{ $t("Forgot password?") }}</a>
        </div>
      </ValidationObserver>
    </DialogCard>
    <LoadingModal :show="loading"/>
  </div>
</template>

<script>
import Header from "@/components/headers/Header";
import DialogCard from "@/components/cards/DialogCard";
import dialogHelper from "@/helpers/dialogHelper";
import authenticateService from "@/services/authenticateService";

export default {
  name: "Login",
  components: {Header, DialogCard},
  data() {
    return {
      loading: false,
      showPass: false,
      formData: {
        username: "",
        password: ""
      }
    }
  },
  created() {
    if (this.$store.getters.account)
      this.gotoPage();
  },
  methods: {
    async gotoPage() {
      this.$bridge.$emit("accountLogin");
      const query = this.$route.query;
      let returnUrl = query.returnUrl;
      if (typeof returnUrl === "string" && returnUrl.length > 0) {
        if (query.redirect == "true") {
          if (query.pushAccessToken == "true") {
            if (returnUrl.indexOf('?') > -1)
              returnUrl += "&"
            else
              returnUrl += "?"

            returnUrl += "accessToken=" + this.$store.getters.accessToken;
          }

          location.href = returnUrl;
          return;
        }

        await this.$router.push({path: returnUrl});
        return;
      }
      await this.$router.push({name: "Account"})
    },
    async onSubmit() {
      const valid = await this.$refs.form.validate();
      if (!valid)
        return;

      this.loading = true;
      await this.$recaptchaLoaded();
      const response = await authenticateService.login(this.formData, await this.$recaptcha('login'))
      this.loading = false;

      if (!response) {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        await dialogHelper.alert(response.message);
        return;
      }

      const data = response.data;
      await this.$store.dispatch("setAccount", data.account);
      await this.$store.dispatch("setAccessToken", data.accessToken);
      await this.$store.dispatch("setGiftCodesForYou", null);
      if (data.hasNewNotification)
        this.$bridge.$emit("hasNewNotification", true);

      await this.gotoPage();
    }
  }
}
</script>
